import React, { Component } from "react";
import "./portfolio.scss";

export default class Porfolio extends Component {
  render() {
    let resumeData = this.props.resumeData;
    // let imageArray = this.props.resumeData.portfolio;
    return (
      <section id="portfolio">
        <div className="row">
          <div className="twelve columns collapsed">
            <h1>Poznaj kilka moich prac</h1>
            <div className="bgrid-halves s-bgrid-halves cf">
              {resumeData.portfolio &&
                resumeData.portfolio.map(item => {
                  return (
                    <div className="columns portfolio-item" key={item.name}>
                      <a
                        href={item.projecturl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <h5>{item.name}</h5>
                        <div className="item-wrap">
                          <span>
                            <img
                              src={`${item.imgurl}`}
                              className="item-image"
                              alt={item.description}
                            />
                            <div className="overlay">
                              <div className="portfolio-item-meta">
                                <p>Narzędzia:</p>
                                <p>{item.description}</p>
                                {item.showskill.map((image, idx) => {
                                  return (
                                    <img
                                      className="maxWidth"
                                      src={`../images/skills/${image}.svg`}
                                      key={image}
                                      alt={image}
                                    />
                                  );
                                })}
                                <hr />
                                <h2>
                                  Strona on-line:
                                  <br /> {item.projecturl}
                                </h2>
                              </div>
                            </div>
                          </span>
                        </div>
                      </a>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
    );
  }
}
