import React, { Component } from "react";
// import GoogleMapsContainer from "../GoogleMaps/GoogleMaps";
import "./contactus.scss";

export default class ContactUs extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="contact">
        <div className="row section-head">
          <div className="ten columns">
            <p className="lead">
              Zapraszam do kontaktu w celu uzyskania dalszych informacji
            </p>
          </div>
        </div>

        <div className="row">
          <aside className="eigth columns footer-widgets">
            <div className="widget">
              <h4>Linked in: {resumeData.linkedinId}</h4>
              <h4>
                email:{" "}
                <a
                  href={`mailto:${resumeData.email}?subject=${resumeData.emailSubject}`}
                >
                  Napisz do mnie
                </a>
              </h4>
            </div>
          </aside>
        </div>
        {/* <div className="gMaps">
          {" "}
          <GoogleMapsContainer />
        </div> */}
      </section>
    );
  }
}
