let resumeData = {
  imagebaseurl: "http://robertlajtar.com/",
  name: "Robert Łajtar",
  role: "Frontend Developer and Graphic Designer.",
  linkedinId: "Robert Łajtar",
  skypeid: "robert.lajtar",
  email: "contact@robertlajtar.com",
  emailSubject: "Zapytanie z robertlajtar.com",
  roleDescription: "Po prostu projektuję i koduję strony. I lubię to.",
  socialLinks: [
    {
      name: "linkedin",
      url: "https://www.linkedin.com/in/robertlajtar/",
      className: "fa fa-linkedin"
    },
    {
      name: "github",
      url: "https://github.com/robbie-PL",
      className: "fa fa-github"
    },
    {
      name: "twitter",
      url: "https://twitter.com/_robbie___",
      className: "fa fa-twitter"
    },
    {
      name: "facebook",
      url: "https://www.facebook.com/robert.lajtar",
      className: "fa fa-facebook"
    },
    {
      name: "youtube",
      url: "https://www.youtube.com/user/OTH0",
      className: "fa fa-youtube"
    },
    {
      name: "vimeo",
      url: "https://vimeo.com/user9873979",
      className: "fa fa-vimeo"
    }
  ],
  aboutme:
    "Posiadam 3 lata doświadczenia w projektowaniu i kodowaniu stron www, głównie w html, css, javascript (es5 i es6). Koduję w środowisku npm, webpack używając wielu rożnych frameworków. Mam za sobą wiele zakończonych z sukcesem projektów. Dodatkowo tworzę grafiki 2D i 3D w programach takich jak Photoshop, Illustrator czy Cinema 4D.",
  aboutme2:
    "Szukasz osoby zaangażowanej w pracę, która przynosi efekty? Nie krępuj się i napisz do mnie, razem możemy więcej. ",
  address: "Poland",
  website: "https://robertlajtar.com",
  education: [
    {
      UniversityName: "Wyższa Szkoła Bankowa w Poznaniu",
      specialization: "Zarządzanie prjektami",
      YearOfStart: "2014",
      YearOfPassing: "2015",
      Achievements:
        "Metodologia PMI, SCRUM (Agile), zarządzanie (budżetem, ryzykiem, zmianami, jakością, zasobami), budowanie i praca zespołów, narzędzia do raportowania oraz analizy ",
      key: "1"
    },
    {
      UniversityName: "Politechnika Koszalińska",
      specialization: "Komputerowe wspomaganie zarządzania",
      YearOfStart: "1994",
      YearOfPassing: "1999",
      Achievements:
        "projektowanie systemów komputerowych, aplikacje internetowe, relacyjne bazy danych, administracja systemami operacyjnymi (UNIX w tym OS X), Windows, komputerowe wspomaganie projektowania, techniki multimedialne",
      key: "2"
    }
  ],
  work: [
    {
      CompanyName: "WHITE R",
      specialization: "Projektowanie i kodowanie stron www",
      YearOfStart: "2016",
      YearOfLeaving: "nadal",
      Achievements:
        "Tworzenie i wykonywanie projektów stron www w technologii HTML/HTML5, stylów CSS/CSS3 oraz frameworków (Bootstrap. Foundation) z zastosowaniem JavaScript (jQuery, React i innych), trochę PHP oraz baz danych SQL. Używanie narzędzi do modelowania i projektowania takich jak Adobe Photoshop, Illustrator oraz Maxon Cinema 4D. Znajomość technik Responsive Web Design, Single Page Application, Progressive Web Application, używanie rozwiązań do testowania i automatyzacji jak Mocha, Gulp. Korzystanie z repozytoriów npm i wielu innych bibliotek.",
      key: "1"
    },
    {
      CompanyName: "Motorola, Orange, Play, Mars, ...",
      specialization: "Menadżer w dziale sprzedaży, ... ",
      YearOfStart: "1997",
      YearOfLeaving: "2016",
      Achievements:
        "Koordynacja i organizacja działań struktury sprzedażowej, kierowanie i zarządzanie zespołem handlowców w kanale tradycyjnym i nowoczesnym w celu realizacji celów sprzedażowych (rekrutacja, planowanie, coaching, motywacja ludzi, kontrola), realizacja zadań sprzedażowych, merchandisingowych przez zespół, optymalizacja kosztów, zasobów w stosunku do celu, weryfikacja wykonywanych zadań przez handlowców, współpraca z kluczowymi dystrybutorami, prowadzenie szkoleń dla przedstawicieli handlowych, analiza konkurencji, rynku, współpraca z innymi partnerami biznesu w celu osiągania zadań.",
      key: "2"
    }
  ],
  skillsDescription: "Wybrane umiejętności",
  skills: [
    {
      skillname: "HTML5"
    },
    {
      skillname: "CSS"
    },
    {
      skillname: "SCSS"
    },
    {
      skillname: "Javascript"
    },
    {
      skillname: "Reactjs"
    },
    {
      skillname: "jQuery"
    },
    {
      skillname: "PHP"
    },
    {
      skillname: "MySQL"
    },
    {
      skillname: "Bootstrap"
    },
    {
      skillname: "Photoshop"
    },
    {
      skillname: "Illustrator"
    },
    {
      skillname: "cinema4d"
    }
  ],

  portfolio: [
    {
      name: "Projekt: Akropol Inwestycje",
      description:
        "HTML5, CSS3, JavaScript, jQuery, PHP, MySQL, Photoshop, Illustrator + many dev tools",
      imgurl: "images/portfolio/ai.jpg",
      projecturl: "http://akropol-inwestycje.pl",
      showskill: ["html5", "css", "sass", "js", "bootstrap", "react"]
    },
    {
      name: "Projekt: Crystal Art Gallery",
      description:
        "HTML5, CSS3, JavaScript, jQuery, Bootstrap, Photoshop, Illustrator",
      imgurl: "images/portfolio/cag.png",
      projecturl: "http://crystalartgallery.net",
      showskill: [
        "react",
        "html5",
        "css",
        "sass",
        "js",
        "php",
        "mysql",
        "bootstrap"
      ]
    },
    {
      name: "Projekt: Dance Cork",
      description:
        "HTML5, CSS3, JavaScript, jQuery, PHP, Bootstrap, Photoshop, Illustrator",
      imgurl: "images/portfolio/dc.png",
      projecturl: "http://dancecork.com",
      showskill: ["html5", "css", "sass", "php", "js", "bootstrap"]
    },
    {
      name: "Projekt: Wymień szybkę",
      description:
        "HTML5, CSS3, JavaScript, jQuery, PHP, Bootstrap, Photoshop, Illustrator",
      imgurl: "images/portfolio/ws.png",
      projecturl: "http://wymien-szybke.pl",
      showskill: ["html5", "css", "sass", "js", "php", "sql", "bootstrap"]
    },
    {
      name: "Projekt: Tłumaczenia hiszpański",
      description:
        "HTML5, CSS3, JavaScript, jQuery, PHP, Bootstrap, Photoshop, Illustrator",
      imgurl: "images/portfolio/th.png",
      projecturl: "http://tlumaczenia-hiszpanski.pl",
      showskill: ["html5", "css", "sass", "js", "php", "sql", "bootstrap"]
    },
    {
      name: "Projekt: Centrum Medical",
      description:
        "HTML5, CSS3, JavaScript, jQuery, PHP, Bootstrap, Photoshop, Illustrator",
      imgurl: "images/portfolio/cm.png",
      projecturl: "http://centrum-medical.pl",
      showskill: ["html5", "css", "sass", "js", "php", "sql", "bootstrap"]
    },
    {
      name: "Projekt: 3D Shield Visualisation",
      description: "Maxon Cinema 4D, Illustrator",
      imgurl: "images/portfolio/shield.png",
      projecturl: "https://robertlajtar.com/but/video/BUTshieldPreview.mp4",
      showskill: ["html5", "css", "sass", "js", "php", "sql", "bootstrap"]
    },
    {
      name: "Projekt: Wiry House Developer",
      description:
        "HTML5, CSS3, JavaScript, jQuery, PHP, MySQL, Photoshop, Illustrator",
      imgurl: "images/portfolio/aiw.png",
      projecturl: "http://wiry.akropol-inwestycje.pl",
      showskill: ["html5", "css", "sass", "js", "php", "sql", "bootstrap"]
    },
    {
      name: "RObert Łajtar portfolio",
      description:
        "ReactJS, HTML5, CSS3, JavaScript, jQuery, Photoshop, Illustrator",
      imgurl: "images/portfolio/rl.jpg",
      projecturl: "https://robertlajtar.com",
      showskill: ["react", "html5", "sass", "js", "bootstrap", "gulp"]
    }
  ],
  testimonials: [
    {
      description: "JavaScript / JSX",
      name: "Robert Łajtar",
      key: "1"
    },
    {
      description: "CSS / SCSS",
      name: "Robert Łajtar",
      key: "2"
    },
    {
      description: "React",
      name: "Robert Łajtar",
      key: "3"
    },
    {
      description: "HTML / Frameworks",
      name: "Robert Łajtar",
      key: "4"
    },
    {
      description: "mySQL / databases",
      name: "Robert Łajtar",
      key: "5"
    },
    {
      description: "npm / Node / developer env",
      name: "Robert Łajtar",
      key: "6"
    }
  ]
};

export default resumeData;
